<template>
  <!-- Brochures -->
  <BrochuresGrid
    :input="{}"
    :pagination="{ limit: 9, offset: 0 }"
    :title="$t('home.new-brochures-this-week')"
    :impression-source="BrochureImpressionSource.NewOpen"
  />
  <CenteredContent class="mt-3 mb-3">
    <BaseButton @click.prevent="loadMoreBrochures">{{
      $t("home.more-brochures")
    }}</BaseButton>
  </CenteredContent>

  <!-- Ad -->
  <Advertisement :display-at="AdDisplayAt.HomepageTop" class="ad" />

  <!-- Shops -->
  <ShopsGrid
    :pagination="{ limit: 10, offset: 0 }"
    :title="$t('home.recommended-shops')"
  />
  <CenteredContent class="mt-3 mb-3">
    <BaseButtonLink to="shops">{{ $t("home.all-shops") }}</BaseButtonLink>
  </CenteredContent>

  <!-- Ad -->
  <Advertisement :display-at="AdDisplayAt.HomepageBottom" class="ad" />

  <!-- Offers -->
  <OffersGrid
    :pagination="{ limit: 8, offset: 0 }"
    :title="$t('home.recommended-offers')"
    class="mb-3"
  />
  <CenteredContent class="mb-3">
    <BaseButtonLink to="offers">{{ $t("home.all-offers") }}</BaseButtonLink>
  </CenteredContent>

  <!-- Mailinglist -->

  <!-- App banner -->
  <DownloadAppBanner />
</template>

<script setup lang="ts">
import { AdDisplayAt, BrochureImpressionSource } from "~/graphql/generated";

// Navigate to the new page and scroll to the current position
const loadMoreBrochures = () => {
  const router = useRouter();
  const localePath = useLocalePath();
  router.push(localePath({ name: "new", query: { noScroll: "1" } }));
};
</script>

<style lang="scss" scoped>
.ad {
  margin-bottom: 6rem;
}
</style>
